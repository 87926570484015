import Decimal from 'decimal.js';

export type WeightUnit = 'kg' | 'lb'

export const weightConvert = {
  kg: '1',
  lb: '2.20462262185',
};

export const convertWeight = (weight: number, fromUnit: WeightUnit, toUnit: WeightUnit) => {
  const a = new Decimal(weight.toString());
  const b = new Decimal(weightConvert[fromUnit]);
  const c = new Decimal(weightConvert[toUnit]);
  const result = a.dividedBy(b).times(c);
  return result.toNumber();
};
