import { useTranslation } from 'react-i18next';
import { Button, Card } from 'toc-styled-components';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import CardContent from './layout/CardContent';
import CardHeading from './layout/CardHeading';
import Grid from './layout/Grid';
import Icon from './layout/Icon';
import Paragraph from './layout/Paragraph';
import forms from '../../lib/forms';
import StyledCard from './layout/StyledCard';
import { hasRole } from '../../lib/helpers';

function Home() {
  const { t } = useTranslation();
  const { user } = useAuth0();
  const allowedForms = forms.filter((form) => hasRole(user!, form.allowedFor));

  return (
    <>
      <Grid>
        {allowedForms.map((form) => (
          <StyledCard key={form.title}>
            <CardContent>
              <Icon><form.icon /></Icon>
              <div>
                <CardHeading>{t(form.title)}</CardHeading>
                <Paragraph>{t(form.description)}</Paragraph>
              </div>
            </CardContent>
            <Button as={Link} to={`/${form.path}`} fullWidth>{t('createReport')}</Button>
          </StyledCard>
        ))}
      </Grid>
      {!allowedForms.length && (
        <Card>
          <h2>{ t('noReports') }</h2>
          <p>{t('noReportsToFill')}</p>
        </Card>
      )}
    </>
  );
}

export default Home;
