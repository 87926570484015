import styled from 'styled-components';
import { breakpoints, spacing } from 'toc-styled-components';

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${spacing(2)};
  @media (min-width: ${breakpoints.sm}) {
    grid-gap: ${spacing(4)};
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: ${breakpoints.md}) {
    grid-gap: ${spacing(4)};
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export default Grid;
