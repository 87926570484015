import styled from 'styled-components';
import { breakpoints, spacing } from 'toc-styled-components';

const Container = styled.div`
  padding: ${spacing(2)};
  max-width: ${breakpoints.lg};
  margin: auto;
  @media (min-width: ${breakpoints.sm}) {
    padding: ${spacing(4)};
  }
`;

export default Container;
