import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Auth0Provider } from '@auth0/auth0-react';
import { GlobalStyle } from 'toc-styled-components';
import App from './components/App';
import { AuthWrapper } from './components/AuthWrapper/AuthWrapper';
import './locale';
import './lib/sentry';
import { DataProvider } from './contexts/DataContext/DataContext';
import { AlertProvider } from './components/Alerts/Alerts';

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN as string}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID as string}
      redirectUri={window.location.origin}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE || process.env.REACT_APP_BACKEND_BASE_URL}
    >
      <GlobalStyle />
      <AlertProvider>
        <AuthWrapper>
          <DataProvider>
            <App />
          </DataProvider>
        </AuthWrapper>
      </AlertProvider>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
