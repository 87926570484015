import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

Sentry.init({
  dsn: 'https://0f398be078444030bd4abc5288637e96@o486030.ingest.sentry.io/5742780',
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.REACT_APP_ENVIRONMENT,
  release: process.env.REACT_APP_COMMIT,
  tracesSampleRate: parseFloat(process.env.REACT_APP_TRACES_SAMPLING_RATE || '0'),
  debug: false,
});

Sentry.setTag('branch', process.env.REACT_APP_BRANCH);
Sentry.setTag('version', process.env.REACT_APP_VERSION);
