import { forwardRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from 'toc-styled-components';
import { useFormContext, useWatch } from 'react-hook-form';
import { useDataContext } from '../../../contexts/DataContext/DataContext';

type Props = {
  name: string
  error?: boolean
  required?: boolean
}

type Interceptor = {
  id: number
  name: string
}

const InterceptorSelect = forwardRef(({ ...props }: Props, ref) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState(`${t('loading')}...`);
  const { setValue, getValues } = useFormContext();
  const interceptor = useWatch({ name: props.name });
  const { setInterceptor, interceptors } = useDataContext();

  useEffect(() => {
    if (interceptors.length) {
      setMessage(t('selectInterceptor'));
      setLoading(false);
    }
  }, [interceptors]);

  useEffect(() => {
    const [interceptorValue] = getValues([props.name]);
    setValue(props.name, interceptorValue);
  }, [interceptors, props.name, getValues, setValue]);

  useEffect(() => {
    const selectedInterceptor = interceptors.find((e: any) => e.id === parseInt(interceptor, 10));
    setInterceptor(selectedInterceptor);
  }, [interceptor, interceptors, setInterceptor]);

  return (
    <Select
      ref={ref}
      disabled={loading || !interceptors.length}
      label={t('interceptor')}
      {...props}
    >
      <option value="">{message}</option>
      {interceptors.map((inter: Interceptor) => (
        <option key={inter.id} value={inter.id}>{inter.name}</option>
      ))}
    </Select>
  );
});

export default InterceptorSelect;
