import { useTranslation } from 'react-i18next';
import { NavLink, Link } from 'react-router-dom';
import styled from 'styled-components';
import { breakpoints, Header, HeaderLink } from 'toc-styled-components';
import { Settings } from './layout';

const OuterWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  @media (max-width: ${breakpoints.sm}) {
    flex-direction: column;
  }
`;

const Wrapper = styled.div`
  display: flex;
  @media (max-width: ${breakpoints.sm}) {
    flex-direction: column;
  }
`;

function AppHeader() {
  const { t } = useTranslation();
  return (
    <Header title={t('')} homeLinkAs={Link}>
      <OuterWrapper>
        <Wrapper>
          <HeaderLink as={NavLink} to="/">{t('forms')}</HeaderLink>
          <HeaderLink as={NavLink} to="/overview">{t('overview')}</HeaderLink>
        </Wrapper>
        <Wrapper>
          <HeaderLink as={NavLink} to="/settings">
            <Settings />
          </HeaderLink>
        </Wrapper>
      </OuterWrapper>
    </Header>
  );
}

export default AppHeader;
