import styled from 'styled-components';
import { breakpoints, colors, spacing } from 'toc-styled-components';

const Icon = styled.div`
  height: ${spacing(6)};
  width: ${spacing(6)};
  font-size: ${spacing(3)};
  border-radius: 500px;
  background: ${colors.secondary};
  color: ${colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;
  margin-right: ${spacing(3)};
  margin-bottom: ${spacing(3)};
  @media (min-width: ${breakpoints.md}) {
    font-size: ${spacing(4)};
    height: ${spacing(8)};
    width: ${spacing(8)};
  }
`;

export default Icon;
