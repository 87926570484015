import { ErrorMessage } from 'toc-styled-components';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import BatchIdInput from '../../components/Form/components/BatchIdInput';
import WeightInputs from '../../components/Form/components/WeightInputs';
import ImageUpload from '../../components/Form/components/ImageUpload/ImageUpload';

function OffloadingForm() {
  const { t } = useTranslation();
  const { register, formState: { errors } } = useFormContext();
  return (
    <>
      <BatchIdInput
        {...register('batch_id', { required: { value: true, message: t('fieldRequired') } })}
        required
        error={!!errors.batch_id}
      />
      <ErrorMessage message={errors.batch_id?.message} />
      <ErrorMessage message={errors.restarted_at?.message} />
      <WeightInputs
        required
        label={t('dumpsterWeights')}
        name="dumpster_weights"
      />
      <ImageUpload
        required
        instructions="imageInstructionsOffloading"
        surveyType="offloading"
        label={t('images')}
        {...register('pictures', { required: true, minLength: 3 })}
      />
    </>
  );
}

export default OffloadingForm;
