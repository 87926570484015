import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ErrorMessage, Input } from 'toc-styled-components';
import { useEffect } from 'react';
import ImageUpload from '../../components/Form/components/ImageUpload/ImageUpload';

function OffloadedWeightForm() {
  const {
    register, setValue, formState: { errors }, watch,
  } = useFormContext();

  const { t } = useTranslation();
  const watchCrates = watch('n_crates');
  const watchCrateWeight = watch('crate_weight');

  useEffect(() => setValue('total_weight', Math.round(watchCrates * watchCrateWeight * 10) / 10), [watchCrates, watchCrateWeight, setValue]);

  return (
    <>
      <Input
        type="number"
        label={t('crateWeight')}
        step="0.1"
        required
        error={!!errors.crate__weight}
        {...register('crate_weight', {
          value: 0,
          valueAsNumber: true,
          required: { value: true, message: t('fieldRequired') },
          min: { value: 0, message: t('shouldBePositive') },
        })}
      />
      <ErrorMessage message={errors.crate_weight?.message} />
      <Input
        type="number"
        label={t('nCrates')}
        step="1"
        required
        error={!!errors.n_crates}
        {...register('n_crates', {
          value: 1,
          valueAsNumber: true,
          required: { value: true, message: t('fieldRequired') },
          min: { value: 0, message: t('shouldBePositive') },
        })}
      />
      <ErrorMessage message={errors.n_crates?.message} />
      <Input
        type="number"
        label={t('totalWeight')}
        readOnly
        error={!!errors.total_weight}
        {...register('total_weight', {
          value: 0,
          valueAsNumber: true,
          required: { value: true, message: t('fieldRequired') },
          min: { value: 0, message: t('shouldBePositive') },
        })}
      />
      <ErrorMessage message={errors.total_weight?.message} />
      <ImageUpload
        required
        instructions="imageInstructionsOffloaded"
        surveyType="offloaded-weight"
        label={t('images')}
        {...register('pictures', {
          required: {
            value: true,
            message: t('fieldRequired'),
          },
          validate: {
            minLengthPictures: (v) => v.length >= 3 || 'Please upload 3 images',
          },
        })}
      />
      <ErrorMessage message={errors.pictures?.message} />

    </>
  );
}

export default OffloadedWeightForm;
