import { forwardRef, Ref } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from 'toc-styled-components';

function LanguageSelect(props: any, ref: Ref<any>) {
  const { i18n } = useTranslation();
  const languages = Object.keys(i18n.services.resourceStore.data);

  const getLanguageName = (lang: string) => {
    const fixedT = i18n.getFixedT(lang);
    return fixedT('language');
  };

  return (
    <Select
      {...props}
      ref={ref}
      label="Language"
    >
      { languages.map((language) => (
        <option
          key={language}
          value={language}
        >
          {getLanguageName(language)}
        </option>
      ))}
    </Select>
  );
}

export default forwardRef(LanguageSelect);
