import { MdDelete, MdUpload, MdShoppingBag } from 'react-icons/md';
import { FaTruck } from 'react-icons/fa';
import Role from '../roles';
import OffloadedWeightForm from './offloaded-weight';
import OffloadingForm from './offloading';
import SortedWeightForm from './sorted-weight';
import TruckOffloadingForm from './truck-offloading';

const forms = [
  {
    title: 'offloadedWeight',
    id: 'offloaded_weight',
    path: 'offloaded-weight',
    description: 'offloadedWeightDescription',
    icon: MdDelete,
    component: OffloadedWeightForm,
    allowedFor: [Role.OffloadedWeightManager, Role.OffloadedWeightOperator],
  },
  {
    title: 'offloading',
    id: 'offloading',
    path: 'offloading',
    description: 'offloadingDescription',
    icon: MdUpload,
    component: OffloadingForm,
    allowedFor: [Role.OffloadingManager, Role.OffloadingOperator],
  },
  {
    title: 'sortedweight',
    id: 'sortedweight',
    path: 'sortedweight',
    description: 'sortedDescription',
    icon: MdShoppingBag,
    component: SortedWeightForm,
    allowedFor: [Role.SortedManager, Role.SortedOperator],
  },
  {
    title: 'truckoffloading',
    id: 'truckoffloading',
    path: 'truckoffloading',
    description: 'truckoffloadingDescription',
    icon: FaTruck,
    component: TruckOffloadingForm,
    allowedFor: [Role.TruckOffloadingManager, Role.TruckOffloadingOperator],
  },
];

export default forms;
