import styled from 'styled-components';
import { Card } from 'toc-styled-components';

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export default StyledCard;
