import { useEffect, useState } from 'react';
import {
  Controller, useFieldArray, useFormContext, useWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  ErrorMessage, Input, Label, Select,
} from 'toc-styled-components';
import { useDataContext } from '../../../../contexts/DataContext/DataContext';
import { WeightInputGrid } from '../../layout';
import { convertWeight, WeightUnit } from './lib';

type Props = {
  label: string
  name: string
  required?: boolean
}

const StyledSelect = styled(Select)`
  margin: 0;
`;

function WeightInputs({
  label, name, required,
}: Props) {
  const { fields, replace } = useFieldArray({ name });
  const { register, getValues, formState: { errors } } = useFormContext();
  const { interceptor } = useDataContext();
  const { weightUnit, setWeightUnit } = useDataContext();
  const weights = useWatch({ name });
  const [noOfDumpsters, setNoOfDumpsters] = useState(6);
  const { t } = useTranslation();
  const units: WeightUnit[] = ['kg'];
  const maximumWeightValue = convertWeight(3000, 'kg', weightUnit);

  useEffect(() => {
    if (weights) {
      const lengthDiff = noOfDumpsters - weights.length;
      if (lengthDiff > 0) {
        replace([
          ...weights,
          ...Array(lengthDiff).fill(0),
        ]);
      } else if (lengthDiff < 0) {
        replace(weights.splice(0, weights.length + lengthDiff));
      }
    }
  }, [noOfDumpsters, replace, weights, name]);

  useEffect(() => {
    const [weightValues] = getValues([name]);
    const fromUnit = weightUnit === 'kg' ? 'lb' : 'kg';
    const convertedWeights = weightValues.map((weight: number) => (
      convertWeight(weight, fromUnit, weightUnit)
    ));
    replace(convertedWeights);
  }, [weightUnit, getValues, replace, name]);

  useEffect(() => {
    if (interceptor) {
      setNoOfDumpsters(interceptor.n_dumpsters);
    }
  }, [interceptor, setNoOfDumpsters]);

  return (
    <>
      <Label>{label}</Label>
      <WeightInputGrid>
        {fields.map((arrayField, index) => (
          <Controller
            name={`${name}.${index}`}
            key={arrayField.id}
            render={({ field }) => {
              const hasError = errors[name] && errors[name][index];
              return (
                <div>
                  <Input
                    label={`${t('dumpster')} ${index + 1}`}
                    type="number"
                    required={required}
                    step={0.1}
                    error={hasError}
                    {...register(`${name}.${index}`, {
                      min: { value: 0, message: t('shouldBePositive') },
                      max: { value: maximumWeightValue, message: t('maximumValue', { max: maximumWeightValue }) },
                      required: { value: true, message: t('fieldRequired') },
                    })}
                    {...field}
                    addition={(
                      <StyledSelect
                        onChange={(e) => setWeightUnit(e.target.value)}
                        value={weightUnit}
                      >
                        {units.map((unit) => (
                          <option key={unit} value={unit}>{unit}</option>
                        ))}
                      </StyledSelect>
                    )}
                    onChange={(e) => {
                      const value = parseFloat(e.target.value);
                      if (!Number.isNaN(value)) {
                        field.onChange(value);
                      } else {
                        field.onChange('');
                      }
                    }}
                  />
                  { hasError && <ErrorMessage message={errors[name][index]?.message} /> }
                </div>
              );
            }}
          />
        ))}
      </WeightInputGrid>
    </>
  );
}

export default WeightInputs;
