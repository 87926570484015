import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  Card, Pagination, Table, isMobile, spacing,
} from 'toc-styled-components';
import { PaginationParameters } from 'toc-styled-components/lib/types';
import api from '../../lib/api';
import { toCamelCase } from '../../lib/helpers';
import { MutedParagraph } from '../Form/layout';
import TableRow from './components/TableRow';

export type User = {
  id: string
  email?: string
  first_name?: string
  last_name?: string
}

export type ReportData = {
  id: number
  report_id: string
  version: number
  survey_type: 'offloaded_weight' | 'offloading'
  internal: boolean
  device_id: number
  offloaded_at: Date
  created_by: User
  status: 'submitted' | 'approved' | 'rejected'
  comments?: string
}

const defaultColumns = [
  'survey_type',
  'device_id',
  'offloaded_at',
  'created_by',
  'status',
];

const SortableTh = styled.th`
  cursor: pointer;
  > span {
    margin-left: ${spacing(1)};
  }
`;

function Overview() {
  const { t } = useTranslation();
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortBy, setSortBy] = useState<string | undefined>('offloaded_at');
  const [sortAscending, setSortAscending] = useState(false);
  const [pagination, setPagination] = useState<PaginationParameters>({
    total: 25,
    size: 25,
    page: 0,
  });
  const columns = isMobile() ? defaultColumns.slice(2) : defaultColumns;

  const getReports = () => {
    const sortOrder = sortAscending ? 'ascending' : 'descending';
    const params = { ...pagination, sort_by: sortBy, sort_order: sortOrder };
    params.page += 1;
    api.get(['overview', 'reports'], { params }).then((r) => {
      setReports(r.data.items);
      if (r.data.total !== pagination.total) {
        setPagination({ ...pagination, total: r.data.total });
      }
    }).finally(() => setLoading(false));
  };

  const sort = (key: string) => {
    if (key === sortBy) {
      setSortAscending(!sortAscending);
    }
    setSortBy(key);
  };

  useEffect(() => {
    // We only trigger a loading animation here so we can 'silently'
    // refresh further down the tree when updating report data with getReports().
    setLoading(true);
    getReports();
  }, [pagination.page, sortBy, sortAscending]);

  const [showVerifiable, setShowVerifiable] = useState(true);

  return (
    <Card>
      <h2>{t('overview')}</h2>
      <MutedParagraph>{t('overviewDescription')}</MutedParagraph>
      <div style={{ marginBottom: spacing(2) }}>
        <span>Show Internal Data? </span>
        <input
          type="checkbox"
          checked={showVerifiable}
          onChange={() => setShowVerifiable(!showVerifiable)}
        />
      </div>
      <Table
        active
        fullWidth
        loading={loading ? true : undefined}
        minRows={pagination.size}
      >
        <thead>
          <tr>
            {columns.map((column) => (
              <SortableTh
                key={column}
                onClick={() => sort(column)}
              >
                {t(toCamelCase(column))}
                {column === sortBy && (
                  <span>{sortAscending ? '▲' : '▼'}</span>
                )}
              </SortableTh>
            ))}
            <th> </th>
          </tr>
        </thead>
        <tbody>
          {
            reports
              .filter((report: ReportData) => showVerifiable || !report.internal)
              .map((report: ReportData) => (
                <TableRow
                  key={report.report_id}
                  data={report}
                  columns={columns}
                  refreshData={getReports}
                />
              ))
          }
        </tbody>
      </Table>
      <Pagination {...pagination} onUpdate={(update) => setPagination(update)} />
    </Card>
  );
}

export default Overview;
