import { User } from '@auth0/auth0-react';
import { Interceptor } from '../contexts/DataContext/DataContext';
import i18n from '../locale';
import Role from './roles';

export const objectFromArrays = (keys: string[], values: any[]) => {
  const object: any = {};
  keys.forEach((key: string, index: number) => {
    object[key] = values[index];
  });
  return object;
};

export const hasRole = (user: User, roles: Role[]) => {
  const userRoles = user!['https://theoceancleanup.com/permissions'];
  const userRoleForms = userRoles.map((permission: string) => permission.split(':', 1)[0]);
  return roles.some((role) => userRoleForms.includes(role));
};

export const truncate = (s: string, limit?: number) => {
  if (!limit) return s;
  if (s.length > limit) {
    return `${s.slice(0, limit)}...`;
  }
  return s;
};

export const toCamelCase = (s: string) => (
  s.replace(/([-_][a-z])/ig, ($1) => $1.toUpperCase()
    .replace('-', '')
    .replace('_', ''))
);

export const toHumanDate = (date: string) => (
  // This function is expecting the following format: "yyyy-mm-ddThh:mm:ss"
  new Date(date).toLocaleString()
);

export const isDate = (date: string) => {
  // This function is expecting the following format: "yyyy-mm-ddThh:mm:ss"
  const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/;
  return regex.test(date);
};

export const parseData = (d: any, maxLength?: number) => {
  switch (typeof d) {
    case 'string': {
      if (isDate(d)) {
        return toHumanDate(d);
      }
      const translatedString = i18n.t(toCamelCase(d));
      if (translatedString === toCamelCase(d)) {
        return truncate(d, maxLength);
      }
      return truncate(translatedString, maxLength);
    }
    case 'number':
      return d;
    case 'object':
      if (Array.isArray(d)) {
        return d.join(', ');
      }
      return JSON.stringify(d);
    default:
      return d;
  }
};

export const getInterceptor = (id: number, interceptors: Interceptor[]) => (
  interceptors.find((interceptor) => interceptor.id === id)
);
