import { useTranslation } from 'react-i18next';
import { MdCheckCircle } from 'react-icons/md';
import { Loader, spacing } from 'toc-styled-components';
import { SaveIndicator } from '../layout';

type Props = {
  draftSaved: boolean
  autoSaving: boolean
  autoSaved: boolean
}

function AutoSaveStatus({ draftSaved, autoSaved, autoSaving }: Props) {
  const { t } = useTranslation();
  let saveStatus = null;
  if (draftSaved) {
    if (autoSaving) {
      saveStatus = <Loader size={3} variant="secondary" />;
    } else if (autoSaved) {
      saveStatus = (
        <>
          <MdCheckCircle size={spacing(3)} />
          {' '}
          <span>{t('saved')}</span>
        </>
      );
    }
  }
  return <SaveIndicator>{saveStatus}</SaveIndicator>;
}

export default AutoSaveStatus;
