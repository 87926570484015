import {
  createElement, FunctionComponent, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button, Loader, Modal, Table,
} from 'toc-styled-components';
import { useFormContext, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import DefaultFields from './components/DefaultFields';
import {
  Footer, FormBody, LoadingOverlay, MutedParagraph, StyledCard,
} from './layout';
import api from '../../lib/api';
import { ReportData } from './types';
import { objectFromArrays } from '../../lib/helpers';
import AutoSaveStatus from './components/AutoSaveStatus';
import { processReportData } from './lib';
import { useDataContext } from '../../contexts/DataContext/DataContext';

type Props = {
  title: string
  description: string
  path: string
  component: FunctionComponent
}

function Form({
  title, description, path, component,
}: Props) {
  const { t } = useTranslation();
  const { weightUnit, interceptor } = useDataContext();
  const saveTimeout = 1500;
  const {
    handleSubmit, formState, getValues, reset, setValue, trigger,
  } = useFormContext();
  const formFields = useWatch();
  const navigate = useNavigate();
  const formPath = path.replace('-', '_');

  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [draftSaved, setDraftSaved] = useState(false);
  const [autoSaved, setAutoSaved] = useState(false);
  const [autoSaving, setAutoSaving] = useState(false);
  const [reportData, setReportData] = useState<ReportData>();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  useEffect(() => {
    // On initial load check if there already is a draft
    reset();
    api.get([formPath, 'draft']).then((response) => {
      if (response.data) {
        setReportData(response.data);
        setDraftSaved(true);
      }
    }).finally(() => setLoading(false));
  }, [formPath, reset, trigger]);

  useEffect(() => {
    // Set form values based on report data from backend
    if (reportData && !loading) {
      const formKeys = Object.keys(getValues());
      Object.entries(reportData).forEach(([key, value]) => {
        if (formKeys.includes(key) && value !== null) {
          setValue(key, value);
        }
      });
    }
  }, [reportData, draftSaved, setValue, getValues, loading, trigger]);

  useEffect(() => {
    // Save draft when device_id and offloaded_at are filled in
    if (formState.isValid && !draftSaved && !loading && !submitting) {
      setLoading(true);
      const draftKeys = ['device_id', 'offloaded_at'];
      const draftValues = getValues(draftKeys);
      const formData = objectFromArrays(draftKeys, draftValues);
      api.post([formPath, 'report'], formData).then((response) => {
        setDraftSaved(true);
        setReportData(response.data);
      }).finally(() => { setLoading(false); });
    }
  }, [draftSaved, formState.isValid, formPath]);

  useEffect(() => {
    // Lazy auto save on form change
    if (draftSaved && reportData && !loading) {
      setAutoSaved(false);
      const timeout = setTimeout(() => {
        setAutoSaving(true);
        const reportId = reportData.id.toString();
        const formData = getValues();
        // eslint-disable-next-line no-console
        const processedData = processReportData(formData, weightUnit);
        api.post([formPath, 'report', reportId], processedData).then(() => {
          setAutoSaving(false);
          setAutoSaved(true);
        });
      }, saveTimeout);
      return () => clearTimeout(timeout);
    }
  }, [formFields, draftSaved, getValues, formPath, reportData, loading, weightUnit]);

  const resetForm = () => {
    reset();
    setAutoSaved(false);
    setReportData(undefined);
    setDraftSaved(false);
  };

  const onSubmit = (data: any) => {
    trigger(undefined, { shouldFocus: true });
    if (reportData) {
      // Ensure Consistency of data
      const reportId = reportData.id.toString();
      const processedData = processReportData(data, weightUnit);
      api.post([formPath, 'report', reportId], processedData).then(() => {
      });
      // Submit report
      setSubmitting(true);
      api.post([formPath, 'report', reportId, 'submit']).then(() => {
        setConfirmationModalOpen(true);
      }).finally(() => setSubmitting(false));
    }
  };

  const onDelete = () => {
    setDeleteModalOpen(false);
    if (reportData) {
      setLoading(true);
      const reportId = reportData.id.toString();
      api.delete([formPath, 'report', reportId]).then(() => {
        resetForm();
      }).finally(() => setLoading(false));
    }
  };

  const returnHome = () => {
    resetForm();
    setConfirmationModalOpen(false);
    navigate('/', { replace: true });
  };

  const dateLabel = title === 'sortedweight' ? t('sortedAt') : t('offloadedAt');
  const [isModalOpen, setIsModalOpen] = useState(true);

  const handleConfirm = () => {
    window.location.href = 'http://riverforms.form.toc.yt';
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <StyledCard>
      <Modal
        header="New form system!"
        confirmLabel="Go to new form system"
        cancelLabel="Continue to Riverforms"
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        open={isModalOpen}
      >
        <p>
          We are rolling out a
          {' '}
          <em>
            new form system
          </em>
          .
        </p>
        <p>
          If you are making a report for
          {' '}
          <b>
            Interceptor 001
          </b>
          , please use the new form system.
          For other Interceptors, we will roll out the new form system soon.
          However, feel free to use the new system as well for the other interceptors!
        </p>
        <p>
          For any issues regarding access or usage, please contact the Data team at
          {' '}
          <b>
            data@theoceancleanup.com
          </b>
        </p>
      </Modal>
      {loading && <LoadingOverlay><Loader variant="secondary" /></LoadingOverlay>}
      <h2>{t(title)}</h2>
      <MutedParagraph>{t(description)}</MutedParagraph>
      <form onSubmit={handleSubmit((data) => { onSubmit(data); })}>
        <FormBody>
          <DefaultFields title={title} />
          {draftSaved && createElement(component)}
        </FormBody>
        <Footer>
          <AutoSaveStatus draftSaved={draftSaved} autoSaving={autoSaving} autoSaved={autoSaved} />
          {draftSaved && (
            <div>
              <Button type="button" onClick={() => setDeleteModalOpen(true)} variant="danger">{t('delete')}</Button>
              <Button type="submit" loading={submitting}>{t('submit')}</Button>
            </div>
          )}
        </Footer>
      </form>
      <Modal
        title={t('areYouSure')}
        confirmLabel={t('delete')}
        cancelLabel={t('cancel')}
        onConfirm={onDelete}
        onCancel={() => setDeleteModalOpen(false)}
        open={deleteModalOpen}
      >
        {t('reportWillBeLost')}
      </Modal>
      <Modal
        title={t('reportSubmitSuccessTitle')}
        confirmLabel={t('returnHome')}
        onConfirm={returnHome}
        open={confirmationModalOpen}
      >
        <FormBody>
          <div>
            <p>
              {t('reportSubmitSuccessMessage')}
            </p>
            <Table>
              <tbody>
                <tr>
                  <th>{t('id')}</th>
                  <td>{reportData?.id}</td>
                </tr>
                <tr>
                  <th>{t('interceptor')}</th>
                  <td>{interceptor?.name}</td>
                </tr>
                <tr>
                  <th>{dateLabel}</th>
                  <td>{new Date(getValues('offloaded_at')).toLocaleString()}</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </FormBody>
      </Modal>
    </StyledCard>
  );
}

export default Form;
