import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReportData } from '../../../Overview';
import ReportModal from '../../ReportModal/ReportModal';

type Props = {
  data: ReportData
}

function TableRow({ data }: Props) {
  const [modalOpen, setModalOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <tr onClick={() => setModalOpen(true)} key={data.id}>
      <td>
        { `${t('version')} ${data.version}` }
      </td>
      <td>
        <ReportModal
          open={modalOpen}
          setOpen={setModalOpen}
          data={data}
          hideControls
        />
      </td>
    </tr>
  );
}

export default TableRow;
