enum Role {
  OffloadedWeightOperator = 'OffloadedWeightOperator',
  OffloadedWeightManager = 'OffloadedWeightManager',
  OffloadingOperator = 'OffloadingOperator',
  OffloadingManager = 'OffloadingManager',
  SortedOperator = 'SortedOperator',
  SortedManager = 'SortedManager',
  TruckOffloadingOperator = 'TruckOffloadingOperator',
  TruckOffloadingManager = 'TruckOffloadingManager',
}

export default Role;
