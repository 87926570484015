import { useAuth0 } from '@auth0/auth0-react';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Button, Dropdown, Modal, Table, TextArea,
} from 'toc-styled-components';
import { RiHistoryFill, RiCloseFill } from 'react-icons/ri';
import api from '../../../../lib/api';
import { hasRole, toCamelCase } from '../../../../lib/helpers';
import Role from '../../../../lib/roles';
import { useAlert } from '../../../Alerts/Alerts';
import { ReportData } from '../../Overview';
import DataParser from '../DataParser';
import EditModal from './components/EditModal';
import ReportHistory from '../ReportHistory/ReportHistory';
import { Container, Header, HeaderIcon } from './layout';

type Props = {
  open: boolean
  setOpen: (open: boolean) => void
  data: ReportData
  refreshData?: () => void
  hideControls?: boolean
}

export type ReportAction = 'approve' | 'reject'

function ReportModal({
  open, setOpen, data, refreshData, hideControls,
}: Props) {
  const { t } = useTranslation();
  const {
    register, formState, getValues, reset,
  } = useFormContext();
  const { user } = useAuth0();
  const { alert } = useAlert();

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [currentAction, setCurrentAction] = useState<ReportAction>();

  const confirmMessage = currentAction ? t(`${currentAction}Confirm`) : t('areYouSure');
  const userIsAdmin = hasRole(
    user!,
    [
      Role.OffloadedWeightManager,
      Role.OffloadingManager,
      Role.SortedManager,
      Role.TruckOffloadingManager,
    ],
  );
  const hideHistory = data.version === 1 || hideControls;
  const isCreatedByCurrentUser = user?.email === data.created_by?.email;
  const showEdit = data.status !== 'approved' && (!userIsAdmin || isCreatedByCurrentUser);
  const showManagerControls = userIsAdmin && !isCreatedByCurrentUser;

  const keysToHide = ['id'];
  const dataToShow = Object.entries(data).filter(([key]) => !keysToHide.includes(key));

  const confirmAction = (action: ReportAction) => {
    reset();
    setCurrentAction(action);
    setConfirmOpen(true);
  };

  const completeAction = () => {
    const path = [data.survey_type, 'report', data.id.toString(), currentAction!];
    const comments = getValues('comments');
    const payload = comments ? { comments } : {};
    api.post(path, payload).then(() => {
      refreshData?.();
      setConfirmOpen(false);
      reset();
    });
  };

  const completeEdit = () => {
    alert({ message: t('completedSuccessfully') });
    refreshData?.();
    setOpen(false);
    setEditOpen(false);
  };

  return (
    <Modal
      open={open}
      onCancel={() => setOpen(false)}
      hideCancel
      header={(
        <Header>
          <Dropdown disabled={hideHistory} title={<RiHistoryFill size={22} />} right>
            <ReportHistory report={data} />
          </Dropdown>
          <HeaderIcon
            onClick={(e) => { e.stopPropagation(); setOpen(false); }}
          >
            <RiCloseFill size={22} />
          </HeaderIcon>
        </Header>
      )}
      footer={
        !hideControls && (
          <>
            {showEdit && <Button onClick={() => setEditOpen(true)}>{t('edit')}</Button>}
            {showManagerControls && (
              <>
                {data.status !== 'rejected' && (
                <Button onClick={() => confirmAction('reject')} variant="danger">
                  {t('reject')}
                </Button>
                )}
                {data.status !== 'approved' && (
                <Button onClick={() => confirmAction('approve')}>
                  {t('approve')}
                </Button>
                )}
              </>
            )}
          </>
        )
      }
    >
      <Table fullWidth>
        <tbody>
          {dataToShow.map((entry) => (
            <tr key={entry[0]}>
              <th>{t(toCamelCase(entry[0]))}</th>
              <td><DataParser name={entry[0]} value={entry[1]} /></td>
            </tr>
          ))}
        </tbody>
      </Table>
      <EditModal
        open={editOpen}
        onCancel={() => setEditOpen(false)}
        onSubmit={completeEdit}
        data={data}
      />
      <Modal
        open={confirmOpen}
        onCancel={() => setConfirmOpen(false)}
        footer={(
          <Button
            onClick={completeAction}
            disabled={!formState.isValid && currentAction !== 'approve'}
          >
            {t('confirm')}
          </Button>
        )}
      >
        <>
          <div>{confirmMessage}</div>
          {['reject', 'approve'].includes(currentAction!) && (
            <Container>
              <TextArea
                {...register('comments', { required: currentAction !== 'approve' })}
                label={t('comments')}
                required={currentAction !== 'approve'}
              />
            </Container>
          )}
        </>
      </Modal>
    </Modal>
  );
}

export default ReportModal;
