import styled from 'styled-components';
import { spacing } from 'toc-styled-components';

const AlertContainer = styled.div`
  padding: ${spacing(2)};
  position: fixed;
  bottom: 0;
  width: 300px;
  max-width: 100%;
  z-index: 100;
  > * {
    margin-top: ${spacing(2)};
  }
`;

export {
  AlertContainer,
};
