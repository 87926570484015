import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Loader, Table } from 'toc-styled-components';
import api from '../../../../lib/api';
import { ReportData } from '../../Overview';
import TableRow from './components/TableRow';

type Props = {
  report: ReportData
}

const Wrapper = styled.div`
  width: 230px;
  max-width: 100%;
`;

function ReportHistory({ report }: Props) {
  const { t } = useTranslation();

  const [reports, setReports] = useState<ReportData[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const path = [report.survey_type, 'report', report.report_id, 'versions'];
    api.get(path).then((response) => {
      setReports(response.data.reports);
    }).finally(() => setLoading(false));
  }, []);

  if (loading) return <Loader size={3} variant="secondary" />;

  const filteredReports = reports.filter((r) => r.id !== report.id);
  if (!filteredReports.length) return <Wrapper>{t('noVersionHistory')}</Wrapper>;

  return (
    <Wrapper>
      <Table fullWidth active>
        <thead>
          <tr>
            <th>{t('versionHistory')}</th>
            <th> </th>
          </tr>
        </thead>
        <tbody>
          {filteredReports.map((r) => (
            <TableRow key={r.id} data={r} />
          ))}
        </tbody>
      </Table>
    </Wrapper>
  );
}

export default ReportHistory;
