import axios from 'axios';
import {
  createContext, ReactNode, useContext, useEffect, useMemo, useReducer,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Alert as StyledAlert } from 'toc-styled-components';
import { AlertContainer } from './layout';

type Alert = {
  id?: number
  message: string
  variant?: 'primary' | 'secondary' | 'danger'
}

type Props = {
  children: ReactNode
  timeout?: number
}

type State = { alerts: Alert[] }

type Context = {
  alert: (a: Alert) => void
}

type Action = {
  type: 'ALERT' | 'REMOVE_OLDEST'
  payload?: Alert
}

const initialState: State = { alerts: [] };

const AlertContext = createContext<Context>({} as Context);

function reducer(state: any, action: Action) {
  switch (action.type) {
    case 'ALERT':
      return { alerts: [...state.alerts, action.payload] };
    case 'REMOVE_OLDEST':
      return { alerts: state.alerts.slice(0, state.alerts.length - 1) };
    default:
      break;
  }
}

export function AlertProvider({ children, timeout = 5000 }: Props) {
  const { t } = useTranslation();

  const [state, dispatch] = useReducer(reducer, initialState);

  const alert = (newAlert: Alert) => {
    dispatch({
      type: 'ALERT',
      payload: { id: new Date().getTime(), ...newAlert },
    });
    setTimeout(() => dispatch({ type: 'REMOVE_OLDEST' }), timeout);
  };

  useEffect(() => {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if ([401, 403].includes(error.response?.status)) {
          alert({ message: t('notAllowed'), variant: 'danger' });
        } else {
          alert({ message: t('somethingWentWrong'), variant: 'danger' });
        }
        return Promise.reject(error);
      },
    );
  }, []);

  const value = useMemo(() => ({ alert }), []);

  return (
    <AlertContext.Provider value={value}>
      <AlertContainer>
        {state?.alerts.map((a: Alert) => (
          <StyledAlert key={a.id} variant={a.variant || 'primary'}>{a.message}</StyledAlert>
        ))}
      </AlertContainer>
      {children}
    </AlertContext.Provider>
  );
}

export const useAlert = () => useContext(AlertContext);
