import styled from 'styled-components';
import { spacing } from 'toc-styled-components';
import { useDataContext } from '../../../contexts/DataContext/DataContext';
import { getInterceptor, parseData, truncate } from '../../../lib/helpers';
import ImageThumbnail from '../../Form/components/ImageUpload/components/ImageThumbnail';

type Props = {
  name: string
  value: any
  maxCharacters?: number
}

const StyledThumbnail = styled(ImageThumbnail)`
  &:not(:first-child) {
    margin-top: ${spacing(2)};
  }
`;

function DataParser({ name, value, maxCharacters }: Props) {
  const { interceptors } = useDataContext();
  const parse = (k: string, v: any) => {
    if (v === undefined || v === null) return '';
    let parsedValue = null;
    switch (k) {
      case 'pictures':
        if (v) {
          return (
            <>
              { v.map((picture: number) => (
                <StyledThumbnail key={picture} id={picture} />
              ))}
            </>
          );
        }
        break;
      case 'device_id':
        parsedValue = getInterceptor(v, interceptors)?.name;
        break;
      case 'created_by': case 'updated_by':
        if (v.last_name) {
          return `${v.last_name}, ${v.first_name}`;
        }
        return v.email || v.id;
      case 'internal':
        return v ? 'Internal (Not For Validation)' : 'For Validation';
      default:
        parsedValue = parseData(v);
    }
    return truncate ? truncate(parsedValue, maxCharacters) : parsedValue;
  };

  return parse(name, value);
}

export default DataParser;
