import {
  createElement, Fragment, useEffect, useState,
} from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Button, Loader, Modal, TextArea,
} from 'toc-styled-components';
import { Props as ModalProps } from 'toc-styled-components/src/components/Modal/Modal';
import api from '../../../../../lib/api';
import forms from '../../../../../lib/forms';
import DefaultFields from '../../../../Form/components/DefaultFields';
import { FormBody, LoadingOverlay } from '../../../../Form/layout';
import { ReportData } from '../../../Overview';

type Props = {
  data: ReportData
  onSubmit?: () => void
} & ModalProps

function EditModal({ data, onSubmit, ...otherProps }: Props) {
  const { t } = useTranslation();
  const {
    getValues, setValue, formState, register, trigger,
  } = useFormContext();
  const [loading, setLoading] = useState(false);
  const form = forms.find((f) => f.id === data.survey_type);

  const submitData = () => {
    trigger(undefined, { shouldFocus: true });
    if (formState.isValid) {
      setLoading(true);
      const path = [data.survey_type, 'report', data.id.toString(), 'edit'];
      const payload = getValues();
      api.post(path, payload).then(() => {
        if (onSubmit) onSubmit();
      }).finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    if (otherProps.open) {
      const formKeys = Object.keys(getValues());

      Object.entries(data).forEach(([key, value]) => {
        if (formKeys.includes(key) && value !== null) {
          if (key === 'internal') {
            setValue(key, value ? 'true' : 'false');
            return;
          }
          setValue(key, value);
        }
      });
    }
  }, [otherProps.open]);

  return (
    <Modal
      footer={(
        <Button
          onClick={() => submitData()}
          disabled={!formState.isValid}
        >
          {t('submit')}
        </Button>
      )}
      {...otherProps}
    >
      {loading && <LoadingOverlay><Loader variant="secondary" /></LoadingOverlay>}
      <FormBody>
        <DefaultFields title={data.survey_type} />
        {createElement(form?.component || Fragment)}
        <TextArea
          required
          label={t('comments')}
          {...register('comments', { required: true })}
        />
      </FormBody>
    </Modal>
  );
}

export default EditModal;
