import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  Button,
  ErrorMessage,
  Input, Select,
} from 'toc-styled-components';
import ImageUpload from '../../components/Form/components/ImageUpload/ImageUpload';
import api from '../api';

export const StyledH3 = styled.h3`
  display: inline-block;
  justify-content: space-between;
  > div {
    font-size: initial;
    font-weight: normal;
  }
`;

function SortedWeightForm() {
  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const { t } = useTranslation();
  const sourceLocation = watch('waste_source_location');
  const wasteCategory = watch('waste_category');

  const autofillLatest = () => {
    api.get(['sortedweight', 'latest']).then((response) => {
      if (response.data) {
        const autoFillFields = ['waste_source_location', 'harvesting_method', 'gully', 'beach', 'legacy clean-up'];
        autoFillFields.map((k) => {
          const v = response.data[k];
          if (v) {
            setValue(k, v);
          }
          return v;
        });
      }
    });
  };

  return (
    <>
      <StyledH3>Catch Information</StyledH3>
      <Button style={{ float: 'right' }} type="button" title={t('autofillLatestTitle')} onClick={() => autofillLatest()}>{t('autofillLatest')}</Button>
      <Input
        {...register('offloaded_date', { setValueAs: (v) => (v || null) })}
        label={t('offloadedAt')}
        type="date"
        error={!!errors.offloaded_date}
      />
      <Select
        {...register('waste_source_location', { required: true })}
        required
        label={t('wasteSourceLocation')}
        error={!!errors.waste_source_location}
      >
        <option value="gully">Gully</option>
        <option value="beach">Beach</option>
        <option value="legacy clean-up">Legacy Clean-up</option>
      </Select>

      <Select
        {...register('harvesting_method', { required: true })}
        required
        multiple
        error={!!errors.harvesting_method}
        tooltip={t('controlKey')}
        label={t('harvestingMethod')}
      >
        <option value="tender">Tender</option>
        <option value="guardian 1">Guardian 1</option>
        <option value="fisherman boat">Fisherman boat</option>
        <option value="truck / road">Truck / Road</option>
        <option value="manual clean-up">Manual clean-up</option>
        <option value="nets">Nets</option>
        <option value="voluntary clean-up">Voluntary clean-up</option>
      </Select>

      {sourceLocation === 'gully' && (
      <Select
        {...register('gully_location', { setValueAs: (v) => (v || null) })}
        error={!!errors.gully_location}
        label={t('gullyLocation')}
      >
        <option value="" label=" " />
        <option value="rae town">Rae Town</option>
        <option value="kingston pen">Kingston Pen</option>
        <option value="barnes">Barnes</option>
        <option value="tivoli">Tivoli</option>
        <option value="aguilar">D&apos;Aguilar</option>
        <option value="shoemaker">Shoemaker</option>
        <option value="mountain view">Mountain View</option>
      </Select>
      )}

      {sourceLocation === 'beach' && (<Input {...register('beach_location')} label={t('beachLocation')} />)}
      {sourceLocation === 'legacy clean-up' && (<Input {...register('legacy_location')} label={t('legacyLocation')} />)}

      <h3>Bag Information</h3>
      <Input
        {...register('bag_id_number', { required: false })}
        error={!!errors.bag_id_number}
        label={t('bagIdNumber')}
      />
      <ErrorMessage message={errors.bag_id_number?.message} />

      <Select
        {...register('waste_category', { required: true })}
        required
        error={!!errors.waste_category}
        label={t('wasteCategory')}
      >
        <option value="PET (good)">PET (good)</option>
        <option value="PET (rejected)">PET (rejected)</option>
        <option value="HDPE (good)">HDPE (good)</option>
        <option value="HDPE (rejected)">HDPE (rejected)</option>
        <option value="hard plastics">Hard plastics</option>
        <option value="soft plastics">Soft plastics</option>
        <option value="foam">Foam</option>
        <option value="other plastics">Other plastics</option>
        <option value="metal">Metal</option>
        <option value="glass">Glass</option>
        <option value="clothing / textiles">Clothing / Textiles</option>
        <option value="shoes">Shoes</option>
        <option value="bulky items">Bulky Items</option>
        <option value="diapers">Diapers</option>
        <option value="organic">Organic</option>
        <option value="residual">Residual</option>
        <option value="other material">Other Material</option>
        <option value="animal">Animal</option>
        <option value="total weight">Total Weight</option>
      </Select>

      {wasteCategory === 'other' && (<Input {...register('other_category_extra')} label={t('wasteCategoryExtra')} />)}

      <Input
        type="number"
        label={t('kgBag')}
        step="0.1"
        required
        error={!!errors.kg_bag}
        {...register('kg_bag', {
          value: 0,
          valueAsNumber: true,
          required: { value: true, message: t('fieldRequired') },
          min: { value: 0, message: t('shouldBePositive') },
        })}
      />
      <ErrorMessage message={errors.kg_bag?.message} />

      <Select
        {...register('destination', { required: true })}
        required
        error={!!errors.destination}
        label={t('destination')}
      >
        <option value="GDSS (landfill)">GDSS (landfill)</option>
        <option value="RPJ">RPJ</option>
        <option value="cemex">Cemex</option>
        <option value="repurpose">Repurpose</option>
        <option value="Jamaican Recycling">Jamaican Recycling</option>

      </Select>

      <ImageUpload
        required
        instructions="imageInstructionsSorted"
        surveyType="sorted-weight"
        label={t('images')}
        {...register('pictures', { required: true, minLength: 1 })}
      />
      <ErrorMessage message={errors.pictures?.message} />

    </>
  );
}

export default SortedWeightForm;
