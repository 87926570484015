import styled from 'styled-components';
import {
  breakpoints, Card, colors, spacing,
} from 'toc-styled-components';

export const StyledCard = styled(Card)`
  max-width: 600px;
  margin: auto;
  position: relative;
`;

export const Footer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: ${spacing(3)};
  button {
    margin-left: ${spacing(1)};
  }
`;

export const FormBody = styled.div`
  & > label {
    margin-top: ${spacing(2)};
  }
  select, input {
    width: 100%;
  }
`;

export const MutedParagraph = styled.p`
  color: ${colors.darkGrey};
`;

export const LoadingOverlay = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.1);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  border-radius: inherit;
`;

export const SaveIndicator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  svg {
    margin-right: ${spacing(0.8)};
  }
`;

export const WeightInputGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${spacing(2)};
  padding-top: ${spacing(1)};
  & > div {
    margin: 0;
  }
  @media (min-width: ${breakpoints.sm}) {
    grid-gap: ${spacing(1.5)} ${spacing(3)};
    grid-template-columns: 1fr 1fr;
  }
`;
