import styled from 'styled-components';
import { RiSettings5Fill } from 'react-icons/ri';
import { breakpoints } from 'toc-styled-components';
import { useTranslation } from 'react-i18next';

const SettingsContainer = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: ${breakpoints.sm}) {
    > svg {
      display: none;
    }
  }
  @media (min-width: ${breakpoints.sm}) {
    > span:nth-child(2) {
      display: none;
    }
  }
`;

export function Settings() {
  const { t } = useTranslation();

  return (
    <SettingsContainer>
      <RiSettings5Fill size={22} />
      <span>{t('settings')}</span>
    </SettingsContainer>
  );
}
