import styled from 'styled-components';
import { spacing } from 'toc-styled-components';
import { colors } from 'toc-styled-components/src/utils/colors';

export const Container = styled.div`
  margin-top: ${spacing(2)};
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: end;
  > * {
    margin-left: ${spacing(2)};
  }
`;

export const HeaderIcon = styled.div`
  cursor: pointer;
  &:hover {
    color: ${colors.primary};
  }
`;
