import { useEffect, useState } from 'react';
import { MdDelete } from 'react-icons/md';
import api from '../../../../../lib/api';
import { DeleteButton, ThumbnailContainer } from '../layout';

type Props = {
  id: number
  onDelete?: (id: number) => void
}

type Image = {
  url: string
}

function ImageThumbnail({ id, onDelete, ...props }: Props) {
  const [image, setImage] = useState<Image>();

  useEffect(() => {
    api.get(['image', id.toString()]).then((response) => {
      setImage(response.data);
    });
  }, [id]);

  return (
    <ThumbnailContainer
      {...props}
      href={image?.url}
      target="_blank"
      key={id}
      url={image?.url}
    >
      {onDelete && (
      <DeleteButton onClick={(e) => { e.preventDefault(); onDelete(id); }}>
        <MdDelete size={25} />
      </DeleteButton>
      )}
    </ThumbnailContainer>
  );
}

export default ImageThumbnail;
