import { useAuth0 } from '@auth0/auth0-react';
import { ReactNode, useEffect, useState } from 'react';
import { Loader } from 'toc-styled-components';
import axios, { AxiosRequestConfig } from 'axios';
import { useTranslation } from 'react-i18next';
import LoadingContainer from '../../layout/LoadingContainer';
import { LoadingText } from '../../layout/shared';

type AuthWrapperProps = {
  children: ReactNode
}

export function AuthWrapper({ children }: AuthWrapperProps) {
  const {
    loginWithRedirect, isLoading, isAuthenticated, getAccessTokenSilently,
  } = useAuth0();
  const [ready, setReady] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    } else if (!isLoading && isAuthenticated) {
      axios.interceptors.request.use(
        async (config: AxiosRequestConfig) => {
          const newConfig = { ...config };
          if (!newConfig.headers) newConfig.headers = {};
          const token = await getAccessTokenSilently();
          newConfig.headers.Authorization = `Bearer ${token}`;
          return newConfig;
        },
      );
      setReady(true);
    }
  }, [isLoading, isAuthenticated]);

  if (ready) {
    return <div>{children}</div>;
  }
  return (
    <LoadingContainer>
      <Loader variant="primary" />
      <LoadingText>{t('authenticating')}</LoadingText>
    </LoadingContainer>
  );
}
