import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ErrorMessage, Input, Select } from 'toc-styled-components';
import InterceptorSelect from './InterceptorSelect';

const validateDate = (value: string) => {
  const currentDate = new Date();
  const selectedDate = new Date(value);

  // TODO there might be timezone issues with this
  if (selectedDate > currentDate) {
    return 'Date cannot be in the future';
  }

  return true;
};

function DefaultFields({ title }: { title: string }) {
  const { t } = useTranslation();
  const { register, getValues, formState: { errors } } = useFormContext();
  const dateLabel = title.toLowerCase() === 'sortedweight' ? t('sortedAt') : t('offloadedAt');

  return (
    <>
      <InterceptorSelect
        {...register('device_id', { required: { value: true, message: t('fieldRequired') } })}
        required
        error={!!errors.device_id}
      />
      <ErrorMessage message={errors.device_id?.message} />
      <Input
        {...register(
          'offloaded_at',
          {
            required: { value: true, message: t('fieldRequired') },
            validate: validateDate,
          },
        )}
        required
        label={dateLabel}
        type="datetime-local"
        error={!!errors.offloaded_at}
      />
      <ErrorMessage message={errors.offloaded_at?.message} />
      <Select
        {...register('internal', { setValueAs: (v) => (v === 'true') })}
        label={t('Validate')}
      >
        <option value="false">For Validation</option>
        <option value="true">Not For Validation</option>
      </Select>
      {getValues().internal && <p className="warning-text">Warning: Internal data, will not be validated by third party</p>}
    </>
  );
}

export default DefaultFields;
