import { useTranslation } from 'react-i18next';
import { Card, Select } from 'toc-styled-components';
import { FormProvider, useForm } from 'react-hook-form';
import { useEffect } from 'react';
import LanguageSelect from './components/LanguageSelect';
import api from '../../lib/api';
import i18n from '../../locale';
import { Settings as SettingsType, useDataContext } from '../../contexts/DataContext/DataContext';

function Settings() {
  const { t } = useTranslation();
  const { ...methods } = useForm({ mode: 'onChange' });
  const { settings, setSettings } = useDataContext();
  const { register, watch } = useForm({ mode: 'onChange', defaultValues: settings });
  const language = watch('language');

  useEffect(() => {
    watch((data) => {
      api.post(['user', 'settings'], data).then(() => {
        setSettings(data as SettingsType);
      });
    });
  }, []);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  return (
    <Card>
      <FormProvider {...methods}>
        <h2>{t('settings')}</h2>
        <LanguageSelect {...register('language')} disabled />
        <Select
          label={t('measurements')}
          {...register('measurements')}
          disabled
        >
          <option value="metric">{t('metric')}</option>
          <option value="imperial">{t('imperial')}</option>
        </Select>
      </FormProvider>
    </Card>
  );
}

export default Settings;
