import { convertWeight, WeightUnit } from './components/WeightInputs/lib';

export const processReportData = (data: any, weightUnit: WeightUnit) => {
  if (data.dumpster_weights && weightUnit !== 'kg') {
    return {
      ...data,
      dumpster_weights: data.dumpster_weights.map((weight: number) => convertWeight(weight, weightUnit, 'kg')),
    };
  }
  return data;
};
