import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { ReportData } from '../Overview';
import DataParser from './DataParser';
import ReportModal from './ReportModal/ReportModal';

type Props = {
  data: ReportData
  columns: string[]
  refreshData: () => void
}

function TableRow({
  data, columns, refreshData,
}: Props) {
  const [modalOpen, setModalOpen] = useState(false);
  const methods = useForm({ mode: 'onChange', defaultValues: { comments: data.comments } });

  const { internal } = data;

  return (
    <tr onClick={() => setModalOpen(true)} className={internal ? 'warning' : ''}>
      <FormProvider {...methods}>
        {columns.map((key) => (
          <td key={key + data.id}>
            <DataParser name={key} value={(data as any)[key]} maxCharacters={25} />
          </td>
        ))}
        <td>
          <ReportModal
            open={modalOpen}
            setOpen={setModalOpen}
            data={data}
            refreshData={refreshData}
          />
        </td>
      </FormProvider>
    </tr>
  );
}

export default TableRow;
