import { useAuth0 } from '@auth0/auth0-react';
import { FormProvider, useForm } from 'react-hook-form';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './Header/Header';
import Container from '../layout/Container';
import forms from '../lib/forms';
import { hasRole } from '../lib/helpers';
import Form from './Form/Form';
import Home from './Home/Home';
import NotFound from './NotFound/NotFound';
import Overview from './Overview/Overview';
import Settings from './Settings/Settings';

function App() {
  const methods = useForm({ mode: 'onChange' });
  const { user } = useAuth0();

  return (
    <BrowserRouter>
      <Header />
      <Container>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/overview" element={<Overview />} />
          <Route path="/settings" element={<Settings />} />
          {forms.map((item) => {
            const allowed = hasRole(user!, item.allowedFor);
            if (!allowed) return null;
            return (
              <Route
                key={item.path}
                path={`/${item.path}`}
                element={(
                  <FormProvider {...methods}>
                    <Form {...item} />
                  </FormProvider>
                )}
              />
            );
          })}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Container>
    </BrowserRouter>
  );
}

export default App;
