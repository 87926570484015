import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Card } from 'toc-styled-components';

function NotFound() {
  const { t } = useTranslation();
  return (
    <Card>
      <h2>{ t('pageNotFound') }</h2>
      <Button as={Link} to="/">{ t('returnHome') }</Button>
    </Card>
  );
}

export default NotFound;
