import {
  forwardRef, Ref, useEffect, useState,
} from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button, Input } from 'toc-styled-components';
import api from '../../../lib/api';

type Props = {
  name: string
  error?: boolean
  required?: boolean
}

const StyledDiv = styled.div`
  font-size: 0.9em;
`;

const BatchIdInput = forwardRef(({ ...props }: Props, ref: Ref<any>) => {
  const { t } = useTranslation();
  const [deviceId, batchId] = useWatch({ name: ['device_id', props.name] });
  const offloadedAt = useWatch({ name: 'offloaded_at' });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const { setValue } = useFormContext();
  const isSet = !!batchId;

  const validateBatchId = () => {
    if (batchId) {
      api.get(['offloading', 'validate_batch_id', batchId]).then((response) => {
        if (response.data.valid_id) {
          setMessage('');
        } else {
          setMessage(t('batchIdDoesNotExist'));
        }
      });
    }
  };

  useEffect(() => { if (isSet) validateBatchId(); }, [isSet]);

  const getSuggestion = () => {
    setLoading(true);
    const payload = { params: { interceptor_id: deviceId, datetime: offloadedAt } };
    api.get(['offloading', 'batch_id_suggestion'], payload).then((response) => {
      setLoading(false);
      if (response.data.batch_id_suggestion === '') {
        setMessage(t('batchIdAutofillFail'));
      } else {
        setValue(props.name, response.data.batch_id_suggestion);
      }
    });
  };

  return (
    <>
      <Input
        {...props}
        ref={ref}
        label={t('batchId')}
        onBlur={validateBatchId}
        addition={(
          <Button
            onClick={getSuggestion}
            loading={loading}
            variant="secondary"
          >
            {t('autofill')}
          </Button>
        )}
      />
      <StyledDiv>{message}</StyledDiv>
    </>
  );
});

export default BatchIdInput;
