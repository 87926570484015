import {
  createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Loader } from 'toc-styled-components';
import { WeightUnit } from '../../components/Form/components/WeightInputs/lib';
import LoadingContainer from '../../layout/LoadingContainer';
import api from '../../lib/api';
import { LoadingText } from '../../layout/shared';

type Props = {
  children: ReactNode
}

export type Interceptor = {
  id: number
  name: string
  n_dumpsters: number
  device_type: string
}

export type Settings = {
  language: string
  measurements: string
}

type Context = {
  interceptor?: Interceptor
  setInterceptor: Dispatch<SetStateAction<Interceptor | undefined>>
  weightUnit: WeightUnit
  setWeightUnit: Dispatch<SetStateAction<WeightUnit>>
  interceptors: Interceptor[]
  setInterceptors: Dispatch<SetStateAction<Interceptor[]>>
  settings: Settings
  setSettings: Dispatch<SetStateAction<Settings>>
}

const defaultSettings = { language: 'en', measurements: 'metric' };

const DataContext = createContext<Context>({} as Context);

export function DataProvider({ children }: Props) {
  const [loading, setLoading] = useState(true);
  const [weightUnit, setWeightUnit] = useState<WeightUnit>('kg');
  const [interceptor, setInterceptor] = useState<Interceptor | undefined>();
  const [interceptors, setInterceptors] = useState<Interceptor[]>([]);
  const [settings, setSettings] = useState<Settings>(defaultSettings);
  const { t } = useTranslation();

  useEffect(() => {
    api.get(['interceptors']).then((response) => {
      setInterceptors(response.data.interceptors);
    });
    api.get(['user', 'settings']).then((response) => {
      setSettings(response.data);
    }).then(() => setLoading(false));
  }, []);

  const value = useMemo(() => ({
    weightUnit,
    setWeightUnit,
    interceptor,
    setInterceptor,
    interceptors,
    setInterceptors,
    settings,
    setSettings,
  }), [weightUnit, interceptor, interceptors, settings]);

  if (loading) {
    return (
      <LoadingContainer>
        <Loader variant="primary" />
        <LoadingText>{t('loadingUserSettings')}</LoadingText>
      </LoadingContainer>
    );
  }

  return (
    <DataContext.Provider value={value}>
      {children}
    </DataContext.Provider>
  );
}

export const useDataContext = () => useContext(DataContext);
